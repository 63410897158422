/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import Section from '../../Grid/Section';

import ChevronDownRegularIcon from './images/chevron-down-regular.inline.svg';

import * as s from './Faq.module.css';

const messages = defineMessages({
  description: {
    id: 'faq.index.description',
    defaultMessage:
      'Sell your CSGO skins fast and cash out instantly for PAYEER, Payoneer and Airtm',
  },
  generalQuestionsLabel: {
    id: 'faq.index.generalQuestionsLabel',
    defaultMessage: 'General',
  },
  paymentAndDeliveryQuestionsLabel: {
    id: 'faq.index.paymentAndDeliveryQuestionsLabel',
    defaultMessage: 'Payment & Delivery',
  },
});

const getFaqQuestions = (lang) => {
  try {
    return require(`../../../intl/questions/faq/${lang}.json`);
  } catch (error) {
    return require(`../../../intl/questions/faq/en.json`);
  }
};

const Faq = () => {
  const { locale, formatMessage } = useIntl();
  const questions = getFaqQuestions(locale);
  const description = formatMessage(messages.description);

  return (
    <Section tagName="div" className={s.root}>
      <p className={s.description}>{description}</p>
      <FaqQuestions
        id="general-questions"
        title={formatMessage(messages.generalQuestionsLabel)}
        questions={questions.general}
      />
      <FaqQuestions
        id="payment-and-delivery-questions"
        title={formatMessage(messages.paymentAndDeliveryQuestionsLabel)}
        questions={questions.paymentAndDelivery}
      />
    </Section>
  );
};

export default Faq;

const FaqQuestions = ({ id, title, questions }) => (
  <section id={id} className={s.questions}>
    <h2 className={s.title}>{title}</h2>
    <ul className={s.questionList}>
      {questions.map((question) => (
        <li id={question.id} key={question.id}>
          <FaqQuestion title={question.title} content={question.content} />
        </li>
      ))}
    </ul>
  </section>
);

FaqQuestions.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};

const FaqQuestion = ({ title, content }) => {
  const [isVisible, setVisibility] = useState(true);
  const handleVisibility = () => setVisibility(!isVisible);
  const handleKeyPress = (event) => {
    event.preventDefault();

    if (event.key === 'Enter') {
      handleVisibility();
    }
  };

  return (
    <article className={s.question}>
      <header
        className={s.questionHeader}
        tabIndex={0}
        role="button"
        onKeyPress={handleKeyPress}
        aria-expanded={isVisible}
        onClick={handleVisibility}
      >
        <h3 className={s.questionTitle}>{title}</h3>
        <ChevronDownRegularIcon
          className={cx(s.questionChevron, { [s.isOpen]: isVisible })}
        />
      </header>
      <div
        role="tabpanel"
        className={cx(s.questionPanel, { [s.isOpen]: isVisible })}
        aria-hidden={!isVisible}
      >
        <ReactMarkdown className="content" remarkPlugins={[gfm]}>
          {content}
        </ReactMarkdown>
      </div>
    </article>
  );
};

FaqQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
