/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import SEO, { createAlternateLinks } from '../../components/Seo';
import Layout from '../../components/Layout';
import Faq from '../../components/Page/Faq';
import Headline from '../../components/Headline';

const messages = defineMessages({
  title: {
    id: 'faq.index.title',
    defaultMessage: 'Frequently asked questions',
  },
  description: {
    id: 'faq.index.description',
    defaultMessage:
      'Sell your CSGO skins fast and cash out instantly for PAYEER, Payoneer and Airtm',
  },
});

const FaqPage = ({
  location: { pathname },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const title = formatMessage(messages.title);
  const description = formatMessage(messages.description);
  const robots = ['en', 'pl', 'ru'].includes(locale) ? null : 'index, nofollow';
  const alternateLinks = createAlternateLinks(originalPath);

  return (
    <>
      <SEO
        lang={locale}
        title={title}
        description={description}
        pathname={pathname}
        link={alternateLinks}
        robots={robots}
      />
      <Layout originalPath={originalPath}>
        <Headline title={title} />
        <Faq />
      </Layout>
    </>
  );
};

FaqPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default FaqPage;
