// extracted by mini-css-extract-plugin
export var description = "Faq-module--description--dUQzB";
export var isOpen = "Faq-module--isOpen--a1xzn";
export var question = "Faq-module--question--ynZUd";
export var questionChevron = "Faq-module--questionChevron--qbJZ9";
export var questionHeader = "Faq-module--questionHeader--5uymv";
export var questionList = "Faq-module--questionList--79Mw6";
export var questionPanel = "Faq-module--questionPanel--mbufk";
export var questionTitle = "Faq-module--questionTitle--bNgZ6";
export var questions = "Faq-module--questions--qkH20";
export var root = "Faq-module--root--01Xu7";
export var title = "Faq-module--title--4y+VF";